import React, { useMemo } from "react";
import { Select } from "@commonsku/styles";

type DropdownProps = {
  values: string | string[];
  options: (string | { label: string; value: string })[];
  onChange: (v: string | string[]) => void;
  placeholder?: string;
  isMulti?: boolean;
  style?: React.CSSProperties;
};
const Dropdown = ({
  options,
  values,
  onChange,
  isMulti = false,
  placeholder = "Search",
}: DropdownProps) => {
  const dropdownOptions = useMemo(
    () =>
      options.map((option) => {
        const value = typeof option === "string" ? option : option.value;
        const label = typeof option === "string" ? option : option.label;
        return { label, value };
      }),
    [options],
  );

  return (
    <Select
      menuPortalTarget={typeof window !== "undefined" ? document.body : null}
      options={dropdownOptions}
      value={
        isMulti
          ? dropdownOptions.filter((op) => values.includes(op.value))
          : dropdownOptions.find((op) => op.value === values)
      }
      isMulti={isMulti}
      placeholder={placeholder}
      onChange={(e) => {
        let value: string | string[] = "";
        if (e) {
          if (Array.isArray(e) || isMulti) {
            value = (e as (typeof dropdownOptions)[number][]).map(
              (op) => op.value,
            );
          } else {
            value = (e as (typeof dropdownOptions)[number]).value;
          }
        }
        onChange(value);
      }}
    />
  );
};

export default Dropdown;
